import { Uploader } from '../shared/uploader';
import { Message } from '../shared/modular_messages';

const logincallbacks = [];
const logoutcallbacks = [];

let init = u => {
    const change = u.name != window.User.name;
    window.User = u;
    if (!change) return;
    if (u.name){
        logincallbacks.forEach( f=>f instanceof Function && f.call(null, u) )
        Message.log("Now logged in as "+u.name, 'user' );
    }
    else {
        logoutcallbacks.forEach( f=>f instanceof Function && f.call(null, u) )
        Message.log("You are now logged out", 'user' );
    }
};

export var User = {
    init: u=>init(u),
    logout: ()=>init(null),
    login: u=>init(u),
    onlogin: f=>logincallbacks.push(f),
    onlogout: f=>logoutcallbacks.push(f)
};

document.addEventListener('DOMContentLoaded',()=> {

    init(window.User);
    
    if (window.User['id'] && window.User['email'].length==0)
        Message.warning( "<a href='/profile'>Please associate an email with this account</a>" , 'user' );
    else if (window.User['id'] && window.User['activated_at'].length==0)
        Message.warning( "Please check your emails to verify this account" , 'user' );

    Uploader.then( response=> {
        console.log("response:",response);
        if (response.hasOwnProperty('user')){
            let user_data = null;
            try {
                user_data = JSON.parse(response.user);
            } catch {
                user_data = response.user;
            }
            init(user_data);
        }
    });
    
});
